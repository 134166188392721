






































































































































































































































import { Component, Vue } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import cellRender from './CellRender.js';
// import CellRender from './CellRender.js';
import CustomHeader from './header.js';
import TagSelect from './userTagCell.vue';
import UserSelect from './userSelectCell.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    UserSelect,
    Multiselect,
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TagSelect,
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   newColumnName = '';

   newGroupName = '';

  showCreateGroupModal =false;

  selectedWellNames = [];

  availableWellNames = [];

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags = ['Tag1', 'Tag2']

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   newCloumnType = '';

   columnDefs: any = [
     {
       field: 'wellName', pinned: 'left', sortable: true, filter: true, headerName: 'Well Name', width: 300, editable: true, checkboxSelection: true,
     },
     {
       field: 'Add Column +',
       sortable: true,
       pinned: 'right',
       headerComponent: 'agColumnHeader1',
       editable: false,

       initialWidth: 150,
       cellRendererFramework: 'cellRender',
       cellRendererParams: {
         clicked(field) {
           alert(`${field} was clicked`);
         },
       },
     },

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

    addTag(newTag) {
      if (newTag) {
        this.availableTags.push(newTag);
        this.selectedTags.push(newTag);
      }
    }

    createGroup(){
      this.selectedTags = [];
      this.selectedWellNames = []
      this.newGroupName = ''
      this.showCreateGroupModal = false;
    }

    methodFromParent(name, type) {
      console.log(type);
      if (name && type && type === 'Assignee') {
        const data = {
          field: name,
          sortable: true,
          editable: false,

          initialWidth: 350,
          cellRendererFramework: 'UserSelect',
        };
        this.columnDefs.push(data);
      } else if (name && type && type === 'Tags') {
        const data = {
          field: name,
          sortable: true,
          editable: false,

          initialWidth: 350,
          cellRendererFramework: 'TagSelect',
        };
        this.columnDefs.push(data);
      } else {
        this.columnDefs.push({
          field: name,
          cellEditorParams: {
            values: ['English', 'Spanish', 'French', 'Portuguese', '(other)'],
          },
          cellEditor: 'agPopupSelectCellEditor',
        });
      }
    }

    get myView() {
      return this.windowWidth < 769 ? 'tasqs-mobile' : 'tasqs-desktop';
    }

    beforeMount() {
      this.context = { componentParent: this };
    }

    get availableColumnTypes() {
      return [
        {
          text: 'Text',
          value: 'Text',
        },
        // {
        //   text: 'Date',
        //   value: 'Date',
        // },
        {
          text: 'Assignee',
          value: 'assignee',
        },
      ];
    }

    closeColumnDialog() {
      this.showColumnMenu = false;
    }

    get enabledWells() {
      return assetsModule.enabledWells;
    }

    async created() {
      await assetsModule.getEnabledWells();
      this.availableWellNames = assetsModule.enabledWells.map(w => {
        return w.wellName;
      })
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }

    addColumn() {
      console.log('add');
      console.log(this.newColumnName);
      if (this.newColumnName) {
        this.columnDefs.push({ field: this.newColumnName });
        this.newColumnName = '';
      }
    }

    addRow() {
      console.log('add');
      if (this.newWellName) {
        this.rowData.push({ wellName: this.newWellName });
        this.newWellName = '';
      }
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }
}

