export default {
  template: `
        <div>
        <div @click="showMenu()" class="customHeaderLabel"><span
        style="display: inline-block; vertical-align: middle; line-height: normal;"
        class="material-icons cursor-pointer"
      >
        add
      </span> Column </div>
        <div v-if="params.enableSorting" @click="onSortRequested('asc', $event)" :class="ascSort" class="customSortDownLabel">
          <i class="fa fa-long-arrow-alt-down"></i></div>
        <div v-if="params.enableSorting" @click="onSortRequested('desc', $event)" :class="descSort" class="customSortUpLabel">
          <i class="fa fa-long-arrow-alt-up"></i></div>
        <div v-if="params.enableSorting" @click="onSortRequested('', $event)" :class="noSort" class="customSortRemoveLabel">
          <i class="fa fa-times"></i>
        </div>


        <div
          v-if="showColumnMenu"
          style="background: rgb(62,75,97); text-align: start;"
          class="fixed w-96 transform right-2 rounded-md translate-x-1
                       z-50 m-1 shadow-lgDarker py-5 px-3 pb-6"
        >
          <div class="grid grid-cols-6 py-2 px-5">
            <span class="col-span-2 mt-1 text-white">Column Name</span>

            <t-input
            v-model="columnName"
              class="col-span-4 h-8"
              :placeholder="'Column Header'"
            />
          </div>

          <p class="text-white p-5">
            COLUMN TYPES
          </p>

          <t-radio-group
           v-model="columnType"
            class="radio--full-width text-white  mt-0 ml-5"
            :options="['Text', 'Assignee', 'Tags']"
          />
          <div
            style="justify-content: end;"
            class="grid grid-flow-col px-5"
          >
          

            <button
              @click="add()"
              style="float: right; width: 100px;"
              class="h-10 rounded-lg text-sm text-white bg-cyan border mt-4 border-cyan"
             
            >
              Add
            </button>
          </div>
        </div>
        </div>
      `,
  data() {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
      columnName: '',
      columnType: '',
      showColumnMenu: false,
    };
  },
  beforeMount() {},
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },

  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    showMenu() {
      this.showColumnMenu = !this.showColumnMenu;
    },

    onSortChanged() {
      // eslint-disable-next-line no-multi-assign
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },

    add(){
      console.log(this.params)
      console.log(this.columnName)
      this.params.context.componentParent.methodFromParent(this.columnName, this.columnType);
      this.columnName = ''
      this.columnType = ''

    },

    onSortRequested(order, event) {
      this.params.setSort(order, event.shiftKey);
    },
  },
};