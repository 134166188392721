<template>
<div>
       <div
          style="background: rgb(62,75,97); text-align: start;"
          class="fixed w-96 transform right-2 rounded-md translate-x-1
                       z-50 m-1 shadow-lgDarker py-5 px-3 pb-6"
        >
  <multiselect
  deselect-label=""
  track-by="value"
  class="customUI col-span-4 ag-input"
  style="background: rgb(55,69,93); max-width: 635px; margin: 4px; padding: 4px; overflow-x: scroll; overflow-y: hidden;"
  :multiple="true"
  v-model="selectUser"
  label="text"
  :max-height="250"

:taggable="true"
    @tag="addTag"
  placeholder=""
  :options="availableUsers"
  :searchable="true"
  :allow-empty="true"
/>
     </div>
</div>
</template>
<script>
import Vue from "vue";
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
export default {
   components: {
    Multiselect
  },

 data() {
        return {
           selectUser: [],
            cancelBeforeStart: true,
            availableUsers: [],
            highlightAllOnFocus: true
        };
    },


    methods: {
       addTag (newTag) {
         console.log(newTag)
         console.log(this.params.value)
      if(newTag){
      const tag = {
        text: newTag,
        value: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
        this.availableUsers.push(tag)
      this.selectUser.push(tag)
      this.params.value =  this.selectUser;
      }
    }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>;

<style scoped>
.customUI >>> .multiselect__tags {
    background: rgb(55,69,93);
    border-color: transparent;
    padding: 0;
}

.customUI >>> .multiselect__select {
  display: none;
}

.customUI >>> .multiselect__tag {
  background: rgb(61,153,170);
}

.customUI >>> .multiselect__content-wrapper{
  position: fixed;
}

.customUI >>> .multiselect__option--highlight {
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__tag-icon:after{
  background:rgb(61,153,170);
}

.customUI >>> i.multiselect__tag-icon{
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__option--highlight:after{
  background:rgb(61,153,170);
}
.customUI >>> .multiselect__content-wrapper {
  position: fixed;
}
</style>
