import Vue from "vue";

export default Vue.extend({
  methods: {
    deleteRow() {
      console.log(this.params)
    },

  },
  template: `
      <span
      @click="deleteRow()"
      style="display: inline-block; vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer"
    >
      delete
    </span>
    `
});
